<template>
  <div class="mall-wrapper">

    <div class="_banner">
      <img :src="headImg" style="width: 100%"  alt=""/>
      <span class="head_btn"  @click="onClickPageLink(headImgLink)"></span>
<!--      <img :src="detailsImg" style="width: 100%"  alt=""/>-->
    </div>
    <!-- 普通 -->
    <div class="_list">
      <div v-for="item of resourceList"
           @click='jumpBtn( item)'>
        <img class="_pic" style="  width: 100%;" :src="item.resources_img" alt="">
      </div>
    </div>

    <div class="_right_bar" @click="showTips = true"  v-if="MerchantDetail!=null" >
      <img :src="MerchantDetail.rule_img">
    </div>
    <van-overlay :show="showTips" :lock-scroll="false" @click="showTips = false" v-if="MerchantDetail!=null">
      <div class="dialog-wrapper" @click.stop>
        <div class="_dialog_closed"><span class="_closed" @click="showTips = false"><van-icon name="clear"/></span>
        </div>
        <div class="_dialog_title">活动细则</div>
        <div class="_dialog_content" v-html="MerchantDetail.rule_text">
        </div>
        <div class="_dialog_footer _only_btn">
          <van-button block round size="small" color="#fb967d" @click="showTips = false">知道了</van-button>
        </div>
      </div>
    </van-overlay>
    <div class="_divider">
      <van-divider :style="{ color: '#cbcbcb', borderColor: '#cbcbcb', padding: '0 16px', fontSize: '12px' }">
        活动说明
      </van-divider>
    </div>
    <div class="_textarea" v-html="MerchantDetail.activity_text">
    </div>

    <div class="_bottom_fixed">
      <div class="_height"></div>
      <div class="_bottom_back">
        <span class="_back_home" @click="$router.push('/home')"></span>
        <span class="_back_prev" @click="$router.go(-1);"></span>
      </div>
    </div>
    <div class="_right_bar_new_card"
         @click="onClickPageLink('https://wx.scbank.cn/ares-web-wechat/wechat/authinit/applyDebitCard.do')">
      <img src="http://pic.eaworks.cn/scbank/uploads/2023922/6294112.png">
    </div>
  </div>
</template>

<script>
import page_links from "../mixin/page_links";
import wx from 'weixin-js-sdk'
// import show_img from "./components/show_img"

export default {
  mixins: [page_links],
  components: { },

  data() {
    return {
      showTips: false,
      dataList: [],
      merchantId: null,
      headImg: "",
      headImgLink:"",
      detailsImg:"",
      MerchantDetail: [],
      resourceList:{},
      listType:""
    };
  },

  created() {
    console.log("-> [index.vue] created");
    this.merchantId = this.$route.query.id;
    this.listType = this.$route.query.listType
    this.getMerchantDetail()
  },

  mounted() {
    this.get_sub_list();
  },

  methods: {
    jumpBtn(item) {
     if (item.id === "6cdc2d41-65ec-4793-9d90-2ec60a7e50c4") {
        this.onClickPageLink("/map?store_type=110220&card_id=10")
      }else if (item.id === "041b8dec-8048-41e9-808f-c542a6ce5873") {
        this.onClickPageLink("/subList?headImgId=48a169c6-99c7-4104-96fb-ac67a26304c7&welfareId=4&listType=2")
      }else if(item.id==="02fa96f6-8f6b-41ee-8af9-050a75c139ec"){
        this.onClickPageLink("/map?store_type=10010&card_id=1")
      }else if(item.link_url.substr(7,14)==="pic.eaworks.cn"){
        this.onClickPageLink("/subOtherId?id=" + item.id + "&welfareId=" + this.MerchantDetail.welfare_id + "&listType=" +this.listType)
      }else if(item.link_url!==""){
       this.onClickPageLink(item.link_url);
     }
    },
    async getMerchantDetail() {
      // 获取商户的详情
      let {data} = await this.$ajax.gateway("/api/api_get_merchant_detail", {
        "METHOD": "get",
        "id": this.merchantId
      });
      this.MerchantDetail = data;
      this.MerchantDetail.rule_text =  this.MerchantDetail.rule_text.replace(/\n/g, '<br>')
      this.MerchantDetail.activity_text =  this.MerchantDetail.activity_text.replace(/\n/g, '<br>')
      // this.headImg =  this.MerchantDetail.head_img
      this.detailsImg =  this.MerchantDetail.details_img

      if (this.MerchantDetail.resource_id !== "") {
        await this.getResourceListByOtherId(data.id)
      }
    },
    async getResourceListByOtherId(id) {
      console.info(this.MerchantDetail.card_id);
      let {data} = await this.$ajax.gateway("/api/api_get_resource_by_other_id", {
        "METHOD": "get",
        "id": id,
        "card_id":this.MerchantDetail.card_id
      });

      if(data.length>0){
        if(data[0].seq===0){
          this.headImg = data[0].resources_img
          this.headImgLink = data[0].link_url
          data.splice(0, 1);
        }
        this.resourceList = data
      }
    },

    async getJsInfo() {
      // 生成网页js签名
      let {data, code} = await this.$ajax.gateway("/api/admin_get_js_sign", {
        "body": {
          "url": location.href.split('#')[0]
        }
      });
      if (code === 200) {
        data.res["jsApiList"] = [
          'checkJsApi',
          'updateTimelineShareData',
          'updateAppMessageShareData',
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'hideAllNonBaseMenuItem',
          'showAllNonBaseMenuItem'
        ]; wx.config(data.res);
      }
      wx.ready(function () {
        wx.updateTimelineShareData({
          title: '四川银行-安逸生活 从此开启', // 分享标题
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 设置成功
          }
        });
        wx.onMenuShareTimeline({
          title: '四川银行-安逸生活 从此开启', // 分享标题
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 用户点击了分享后执行的回调函数
          }
        });
        wx.updateAppMessageShareData({
          title: '四川银行', // 分享标题
          desc: '安逸生活 从此开启', // 分享描述
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 设置成功
          }
        });
      });

      wx.error(function (res) {
        console.info("wx error", res);
      });
    },

    async get_sub_list() {
      // 获取商户项目
      let res = await this.$ajax.gateway("/api/api_get_merchant_by_welfare", {
        "METHOD": "get",
        "welfare_id": this.welfareId,
        "list_type": this.listType,
        "pageSize": 99
      });
      this.dataList = res.data;
    }

  }
};
</script>

<style lang="less" scoped>
.mall-wrapper {
  background: #ebebeb;
  position: relative;

  ._banner {
    img {
      display: block;
      width: 100%;
    }
  ;
    margin-bottom: 10px;
  }

  /* 商家列表 */

  ._list {
    padding: 0.1rem 0.4rem 0.2rem;
    min-height: 10rem;

    ._item {
      position: relative;
      margin-bottom: 0.4rem;
      background: #fff;
      padding: 0.2rem;
      border-radius: 0.2rem;
      box-shadow: 0 6px 12px 0 rgba(30, 38, 51, 0.12);

      img._pic {
        display: block;
        height: 2.1rem;
        margin-left: 0.2rem;
      }

      ._name {
        padding-top: 0.35rem;
        line-height: 2em;
        font-size: 0.42rem;
      }

      ._desc {
        color: #999;
        font-size: 0.32rem;
      }

      ._btn {
        position: absolute;
        top: 0.4rem;
        right: 0.5rem;

        img {
          width: 1.7rem;
        }
      }
    }
  }

  // 食，标题
  ._sub_title {
    font-size: 0.32rem;
    color: #ec7453;
    padding: 0.2rem 0.3rem 0.2rem 0.2rem;
    margin-top: 0.4rem;

    span {
      display: inline-block;
      line-height: 0.55rem;
      padding: 0 0.4rem 0 0.5rem;
      background: url("../status/img/mail_title_bg.png") no-repeat right center;
      background-size: auto 100%;
      font-size: 0.4rem;
      letter-spacing: 0.05rem;
      color: #595757;
      margin-right: 0.15rem;
    }

    &._sub_title2 {
      text-align: right;
      padding-left: 0;
      color: #888;
      text-decoration: underline;

      span {
        background: url("../status/img/mail_title_bg2.png") no-repeat right center;
        background-size: 70% auto;
        float: left;
        text-decoration: none;
        border-radius: 100px;
      }
    }
  }

  ._house_list {
    position: relative;
    padding: 0.2rem 0.3rem;
    border-radius: 0.3rem;

    ._item {
      background: #fff;
      border-radius: 0.4rem;
      padding: 0.2rem;
      margin-bottom: 0.4rem;
      //box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.01);
      ._views {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
        border-radius: 0.3rem;
        text-align: center;

        img._pic {
          display: block;
          width: 100%;
          height: 5.8rem;
          border-radius: 0.3rem 0.3rem 0 0;
        }

        ._title {
          font-size: 0.45rem;
          line-height: 1.8em;
          color: #333;
        }

        ._dsc {
          font-size: 0.2rem;
          color: #888;
          padding-bottom: 0.4rem;
        }
      }

      ._foot {
        text-align: center;

        span.btn_h {
          display: inline-block;
          width: 2.9rem;
          padding: 0.1rem 0 0.3rem;
          line-height: 0.6rem;
          background: url("../status/img/btn_h1.png") no-repeat right center;
          background-size: 100% auto;
          text-align: center;
          color: #fff;
          font-size: 0.3rem;
        }
      }
    }

    &._house_list2 {
      ._item {
        margin-bottom: 0.3rem;

        ._views {
          img._pic {
            height: 3.8rem;
          }

          ._title {
            font-size: 0.4rem;
            line-height: 2em;
          }
        }

        ._foot {
          margin: -0.1rem auto;

          span.btn_h {
            width: 2.4rem;
            padding: 0.15rem 0 0.3rem;
            line-height: 0.5rem;
            background: url("../status/img/btn_h2.png") no-repeat right center;
            background-size: 100% auto;
          }
        }
      }
    }
  }

  ._food_list {
    position: relative;
    padding: 0.5rem 0.4rem;

    ._item {
      margin: 0 auto 0.3rem;
      background: #fff;
      border-radius: 0.3rem;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);

      img._pic {
        display: block;
        border-radius: 0.3rem;
        width: 100%;
      }
    }
  }

  ._sale_list_a, ._sale_list_b {
    position: relative;
    //padding: 0.25rem;
    img {
      display: block;
      width: 100%;
    }

    span {
      display: inline-block;
      width: 4rem;
      height: 1.9rem;
      position: absolute;
    }
  }

  ._sale_list_a {
    span._left {
      left: 0.7rem;
      bottom: 0.5rem;
    }

    span._right {
      right: 0.7rem;
      bottom: 0.5rem;
    }
  }

  ._sale_list_b {
    span._left {
      width: 2.4rem;
      height: 3rem;
      left: 0.7rem;
      bottom: 0.6rem;
    }

    span._middle {
      width: 2.4rem;
      height: 3rem;
      left: 3.3rem;
      bottom: 0.5rem;
    }

    span._right {
      width: 2.5rem;
      height: 3.3rem;
      right: 0.7rem;
      bottom: 0.5rem;
    }
  }

  ._vip_mail_list {
    position: relative;
    padding: 0.2rem 0.4rem;

    ._item {
      //background: #fff;
      //border-radius: 0.3rem;
      //box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);

      img._pic {
        display: block;
        border-radius: 0.3rem;
        width: 100%;
      }
    }
  }

  ._vip_benefit_list {
    padding: 0.5rem 0.3rem;

    ._item {
      margin-bottom: 0.4rem;

      img {
        display: block;
        width: 100%;
      }
    }
  }

  ._right_bar_new_card {
    position: fixed;
    top: 0.3rem;
    right: 0rem;

    img {
      height: 2.7rem;
    }
  }
}


.dialog-wrapper {
  width: 80%;
  margin: 20% auto 0;
  background: #fff url("../status/img/dialog_top.png") no-repeat top left;
  background-size: 1.2rem auto;
  padding: 0.2rem 0.3rem 0.4rem;
  border-radius: 0.45rem;

  ._dialog_closed {
    text-align: right;
    color: #f56a4b;
    font-size: 0.45rem;
    margin-bottom: -0.2rem;
    position: relative;
    z-index: 1;
  }

  ._dialog_title {
    padding: 0 0.2rem 0.4rem;
    text-align: center;
    font-size: 0.45rem;
    color: #333;
  }

  // 修改高度
  ._dialog_content {
    max-height: 62vh;
    color: #333;
    font-size: 0.4rem;
    overflow: auto;
    line-height: 1.7em;
    padding: 0 0.2rem 0.3rem;

    p {
      margin-bottom: 0.3rem;
    }
  }

  ._dialog_footer {
    text-align: center;
    padding: 0.2rem 0;

    &._only_btn {
      padding: 0.1rem 1rem;
    }
  }
}
._right_bar {
  position: fixed;
  top: 2.6rem;
  right: -0.2rem;

  img {
    height: 3.5rem;
  }

}
._textarea {
  padding: 0 1rem 0.6rem;
  font-size: 0.3rem;
  color: #afafaf;
  line-height: 1.6em;

  p {
    margin-bottom: 0.1rem;
  }
}
.head_btn{
  top: 5.7rem;
  left: 1rem;
  height: 1rem;
  width: 8rem;
  position: absolute;
}


</style>
